import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faGear, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Domain, getDomainPermission, hasPagePermission, PermissionsMap } from '../../api/permissions_api';
import { Mode } from '../../shared/helpers/modeHelper';

type AdminDropdownProps = {
  permissionMap: PermissionsMap;
};

const AdminDropdown = (props: AdminDropdownProps) => {
  const { t } = useTranslation();

  const hasPermission = (permissionDomain: Domain) => {
    return hasPagePermission(Mode.VIEW, getDomainPermission(props.permissionMap, permissionDomain));
  };

  const hasSponsorTabPermission = hasPermission(Domain.SPONSOR_TAB);
  const hasHomePermission = hasPermission(Domain.HOME);
  const hasSchoolPermission = hasPermission(Domain.SCHOOL);
  const hasSkillPermission = hasPermission(Domain.SKILL);
  const hasEmailNotificationPermission = hasPermission(Domain.EMAIL_NOTIFICATION);

  let canViewSponsorshipTab = props.permissionMap.pagePermissions.canViewSponsorshipTab;

  const canViewAdminDropdown = hasHomePermission || hasSchoolPermission || hasSkillPermission || canViewSponsorshipTab;
  if (!canViewAdminDropdown) {
    return null;
  }
  return (
    <Dropdown className="m-1">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        <FontAwesomeIcon icon={faGear} color="grey" /> {t('admin.TITLE')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {hasSponsorTabPermission && (
          <Dropdown.Item as={Link} to="/child-sponsorship/search">
            <FontAwesomeIcon icon={faHandHoldingDollar} /> {t('sponsor.SPONSORSHIPS')}
          </Dropdown.Item>
        )}
        {hasHomePermission && (
          <Dropdown.Item as={Link} to="/home/search">
            <FontAwesomeIcon icon={'home'} /> {t('admin.HOME')}
          </Dropdown.Item>
        )}
        {hasSchoolPermission && (
          <Dropdown.Item as={Link} to="/school/search">
            <FontAwesomeIcon icon={'school'} /> {t('admin.SCHOOL')}
          </Dropdown.Item>
        )}
        {hasSkillPermission && (
          <Dropdown.Item as={Link} to="/skill/search">
            <FontAwesomeIcon icon={'chalkboard-user'} /> {t('admin.SKILL')}
          </Dropdown.Item>
        )}
        {hasEmailNotificationPermission && (
          <Dropdown.Item as={Link} to="/event-types/search">
            <FontAwesomeIcon icon={'list'} /> {t('admin.EVENT_NOTIFICATIONS')} & <br /> {t('admin.EVENT_TASKS')}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminDropdown;
