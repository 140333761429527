import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { ChildSponsorship } from '../typings/api-types';

const CHILD_SPONSORSHIP_REST_URL = '/child-sponsorship';
const CHILD_SPONSORSHIP_FOR_CHILD_REST_URL = '/child-sponsorship-for-child';

const useGetChildSponsorship = () => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(['useGetChildSponsorship', requestConfig], async () => {
    const response = await axios.get<ChildSponsorship[]>(CHILD_SPONSORSHIP_REST_URL + '/', requestConfig);
    return response.data;
  });
};

const useGetChildSponsorshipForChild = (childId?: number) => {
  const requestConfig = {} as AxiosRequestConfig;

  return useQuery(
    ['useGetChildSponsorshipForChild', requestConfig, childId],
    async () => {
      const response = await axios.get<ChildSponsorship[]>(
        CHILD_SPONSORSHIP_FOR_CHILD_REST_URL + '/' + childId,
        requestConfig,
      );
      return response.data;
    },
    { enabled: !!childId },
  );
};

export { useGetChildSponsorship, useGetChildSponsorshipForChild };
