import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { hasPermission } from '../shared/helpers/permissionHelper';
import { Mode } from '../shared/helpers/modeHelper';
import { Permission } from '../typings/api-types';

const GET_ALL_PERMISSIONS_URL = '/permissions';

type DomainObjectPermissionSet = {
  canView: boolean;
  canChange: boolean;
  canAdd: boolean;
  canDelete: boolean;
  canOverride: boolean;
};

type PermissionsMap = {
  domainPermissions: DomainPermissions;

  pagePermissions: {
    canViewPhysicalTab: boolean;
    canViewMentalTab: boolean;
    canViewEducationTab: boolean;
    canViewTransitionTab: boolean;
    canViewSponsorshipTab: boolean;
  };
};

enum Domain {
  CHILD_ALERT = 'childalert',
  CHILD = 'child',
  CHILD_PHOTO = 'childphoto',
  HOME = 'home',
  HOME_BMI = 'homebmicheck',
  CHILD_DOCTOR_APPOINTMENT = 'childdoctorappointment',
  CHILD_EMOTIONAL_CHECK_IN = 'childemotionalcheckinresult',
  CHILD_BECK_RESULT = 'childbeckresult',
  CHILD_RSCA_RESULT = 'childrscaresult',
  CHILD_WRAT_RESULT = 'childwratresult',
  CHILD_TONI_RESULT = 'childtoniresult',
  CHILD_SEE_RESULT = 'childseeresult',
  SCHOOL = 'school',
  CHILD_GRADE_REPORT = 'childgradereport',
  SKILL = 'skill',
  HOME_SKILL_TRAINING = 'homeskilltraining',
  VACCINATION = 'vaccination',
  EMAIL_NOTIFICATION = 'countryeventtypenotification',
  GUIDANCE_SESSION = 'guidancesession',
  EXTRACURRICULAR = 'childextracurricular',
  CHILD_IMPACT_RESULT = 'childimpactresult',
  CHILD_RIASEC_RESULT = 'childriasecresult',
  CHILD_TRANSITION_PROJECT = 'childtransitionproject',

  PHYSICAL_TAB = 'physical_tab',
  MENTAL_TAB = 'mental_tab',
  EDUCATION_TAB = 'education_tab',
  TRANSITION_TAB = 'transition_tab',
  SPONSOR_TAB = 'sponsor_tab',
}

const createDomainObjectPermissions = (
  allPermissions: Permission[],
  domainObjectKey: string,
): DomainObjectPermissionSet => {
  return {
    canView: hasPermission(allPermissions, 'view_' + domainObjectKey),
    // Need a user to edit some objects but not others?
    // Consider using the actions property pattern from operations management.
    canChange: hasPermission(allPermissions, 'change_' + domainObjectKey),
    canAdd: hasPermission(allPermissions, 'add_' + domainObjectKey),
    canDelete: hasPermission(allPermissions, 'delete_' + domainObjectKey),
    canOverride: hasPermission(allPermissions, 'override_' + domainObjectKey),
  };
};

type DomainPermissions = {
  // Would prefer to do something like [key in Domain]? but struggling with typescript
  [key: string]: DomainObjectPermissionSet;
};

const usePermissions = () => {
  const requestConfig = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;
  const {
    isLoading: isLoadingPermissions,
    error,
    data,
  } = useQuery(['getAllPermissions', requestConfig], async () => {
    const response = await axios.get<Permission[]>(GET_ALL_PERMISSIONS_URL, requestConfig);
    return response.data;
  });

  let permissionMap;
  if (!isLoadingPermissions && !error && data !== undefined) {
    let allPermissions = data;

    const domainPermissions: DomainPermissions = {};
    const domainStrings = Object.values(Domain);
    domainStrings.forEach((domainString) => {
      const domainObjectPermissions = createDomainObjectPermissions(allPermissions, domainString);
      domainPermissions[domainString] = domainObjectPermissions;
    });

    const canViewPhysicalTab = hasPermission(allPermissions, 'view_physical_tab');
    const canViewMentalTab = hasPermission(allPermissions, 'view_mental_tab');
    const canViewEducationTab = hasPermission(allPermissions, 'view_education_tab');
    const canViewTransitionTab = hasPermission(allPermissions, 'view_transition_tab');
    const canViewSponsorshipTab = hasPermission(allPermissions, 'view_sponsor_tab');

    permissionMap = {
      domainPermissions: domainPermissions,

      pagePermissions: {
        canViewPhysicalTab,
        canViewMentalTab,
        canViewEducationTab,
        canViewTransitionTab,
        canViewSponsorshipTab,
      },
    };
  } else {
    permissionMap = null;
  }

  const permissionResponse = {
    isLoadingPermissions,
    error,
    permissionMap,
  };

  return permissionResponse;
};

const getDomainPermission = (permissionMap: PermissionsMap, domain: Domain) => {
  return permissionMap.domainPermissions[domain];
};

const hasPagePermission = (mode: Mode, permissionSet: DomainObjectPermissionSet | undefined) => {
  if (!permissionSet) {
    return false;
  }
  return (
    (mode === Mode.VIEW && permissionSet.canView) ||
    (mode === Mode.CREATE && permissionSet.canAdd) ||
    (mode === Mode.EDIT && permissionSet.canChange)
  );
};

export { Domain, getDomainPermission, usePermissions, hasPagePermission };
export type { PermissionsMap };
